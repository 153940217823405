const FAVORITE_LINKS = [
    {
        'title': 'Tooth Plaque Can Crawl',
        'date': 'Oct. 10, 2022',
        'url': 'https://penntoday.upenn.edu/news/microbes-cause-cavities-can-form-superorganisms-able-crawl-and-spread-teeth'
    },
    {
        'title': 'William Shatner\'s reaction to his first trip to space',
        'date': 'Oct. 8, 2022',
        'url': 'https://www.openculture.com/2022/08/hear-the-worlds-oldest-known-song-hurrian-hymn-no-6-written-3400-years-ago.html'
    },
    {
        'title': 'Hear the World’s Oldest Known Song, “Hurrian Hymn No. 6” Written 3,400 Years Ago',
        'date': 'Aug. 29, 2022',
        'url': 'https://www.openculture.com/2022/08/hear-the-worlds-oldest-known-song-hurrian-hymn-no-6-written-3400-years-ago.html'
    },
    {
        'title': 'Norway Invented Salmon Sushi',
        'date': 'Aug. 16, 2022',
        'url': 'https://www.npr.org/2015/09/18/441530790/how-the-desperate-norwegian-salmon-industry-created-a-sushi-staple'
    },
    {
        'title': 'The Maintenance Race (sailing race around the world)',
        'date': 'Aug. 1, 2022',
        'url': 'https://www.worksinprogress.co/issue/the-maintenance-race/'
    },
    {
        'title': 'Dangerous playgrounds of the 1900s',
        'date': 'June. 24, 2022',
        'url': 'https://rarehistoricalphotos.com/dangerous-playgrounds-1900s/'
    },
    {
        'title': 'The crazy arms race between vaginas and penises',
        'date': 'Apr. 1, 2022',
        'url': 'https://www.smithsonianmag.com/science-nature/why-have-female-animals-evolved-such-wild-genitals-180979813/'
    },
    {
        'title': 'J. R. R. Tolkien Art',
        'date': 'Mar. 22, 2022',
        'url': 'https://www.tolkienestate.com/painting/'
    },
    {
        'title': 'Cocaine in Sherlock Holmes',
        'date': 'Feb. 11, 2022',
        'url': 'http://www.historytoday.com/archive/history-matters/seven-cent-solution'
    },
    {
        'title': 'Militarized Dolphins Protect Almost a Quarter of Nuclear Stockpile',
        'date': 'Jan. 25, 2022',
        'url': 'https://www.military.com/history/militarized-dolphins-protect-almost-quarter-of-us-nuclear-stockpile.html'
    },
    {
        'title': 'Why Skyscrapers Are So Short',
        'date': 'Jan. 23, 2022',
        'url': 'https://www.worksinprogress.co/issue/why-skyscrapers-are-so-short/'
    },
    {
        'title': 'Third Reich: The Rise & Fall',
        'date': 'Jan 12., 2022',
        'url': 'https://www.youtube.com/watch?v=AG726uamib0&t=1937s'
    },
    {
        'title': '1922 Predictions for 100 Years into the Future',
        'date': 'Jan. 6, 2022',
        'url': 'https://www.loc.gov/resource/sn83045774/1922-05-07/ed-1?sp=87&r=0.145,1.056,0.183,0.259,0'
    },
    {
        'title': 'How the Austro-Hungarian Empire Destroyed Itself',
        'date': 'Dec. 28, 2021',
        'url': 'https://www.youtube.com/watch?v=uRxsLwqx4VM'
    },
    {
        'title': 'Vienna - City of Dreams',
        'date': 'Dec 26., 2021',
        'url': 'https://www.youtube.com/watch?v=AFZBOTgL_Hk'
    },
    {
        'title': 'Bugs are evolving to eat plastic',
        'date': 'Dec. 20, 2021',
        'url': 'https://www.nationalobserver.com/2021/12/17/news/bugs-are-evolving-eat-plastic'
    },
    {
        'title': 'The Death of Yugoslavia',
        'date': 'Nov. 26, 2021',
        'url': 'https://www.youtube.com/watch?v=bVUg-VoPAeA'
    },
    {
        'title': 'Baking bread with 4,000-year-old yeast',
        'date': 'Nov. 1, 2021',
        'url': 'https://twitter.com/seamusblackley/status/1158264819503419392?s=21'
    },
    {
        'title': 'History of the deadly tomato',
        'date': 'Nov. 1, 2021',
        'url': 'https://www.smithsonianmag.com/arts-culture/why-the-tomato-was-feared-in-europe-for-more-than-200-years-863735/'
    },
    {
        'title': 'The Secret Case of Sexuality on the Front',
        'date': 'Oct. 1, 2021',
        'url': 'https://www.smh.com.au/national/a-secret-history-of-sexuality-on-the-front-20121220-2bp9m.html'
    },
    {
        'title': 'Barcelona bike bus',
        'date': 'Oct. 1, 2021',
        'url': 'https://twitter.com/zachklein/status/1450113164775870468?s=21'
    },
    {
        'title': 'Hardened wood to replace steel',
        'date': 'Oct. 1, 2021',
        'url': 'https://www.cell.com/matter/fulltext/S2590-2385(21)00465-3'
    },
    {
        'title': 'The Wizard of New Zealand',
        'date': 'Oct. 1, 2021',
        'url': 'https://www.theguardian.com/world/2021/oct/15/new-zealand-council-ends-contract-with-wizard-after-two-decades-of-service'
    },
    {
        'title': 'Enzyme that can decompose plastic',
        'date': 'Oct. 1, 2021',
        'url': 'https://contest.techbriefs.com/2021/entries/sustainable-technologies-future-energy/11344'
    },
    {
        'title': 'Live view of shipping vessels',
        'date': 'Oct. 1, 2021',
        'url': 'https://www.marinetraffic.com/en/ais/home/centerx:163.6/centery:7.4/zoom:3'
    },
    {
        'title': 'Dali and Disney animated short',
        'date': 'Oct. 1, 2021',
        'url': 'https://www.youtube.com/watch?v=y_TlaxmOKqs'
    },
    {
        'title': 'Searching for Sugar Man',
        'date': 'Sep. 11, 2021',
        'url': 'https://www.imdb.com/title/tt2125608/'
    }
]

exports.FAVORITE_LINKS = FAVORITE_LINKS;