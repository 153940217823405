import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import BaseLayout from "../../components/layout"
import Seo from "../../components/seo";
import HorizontalRule from "../../components/horizontal-rule";
import BreadcrumbTrail from "../../components/breadcrumb-trail";
import FAVORITE_LINKS from "../../../favorite-links";

export default function PrivacyPolicyPage(props) {

  let breadcrumbList = [
    [
      {
        title: 'Home',
        url: '/'
      },
      {
        title: 'Favorite Links',
      }
    ]
  ]

  return (
    <BaseLayout>
      <Seo title="Favorite Links" pathname={props.location.pathname} breadcrumbList={breadcrumbList}></Seo>
      <div className="layout-base-w app-p-base">
        <BreadcrumbTrail breadcrumbTrail={breadcrumbList[0]}></BreadcrumbTrail>
        <div className="mb-16 md:mb-28">
          <div className="text-mint">
            <HorizontalRule ruleType="1"></HorizontalRule>
            <h1 className="text-header-1 w-full">Favorite Links</h1>
          </div>
        </div>

        <div className="mt-20">
          {FAVORITE_LINKS.FAVORITE_LINKS.map((item, index) => (
            <p className="text-body-2 mb-12" key={index}><a className="text-link" href={item.url} target="_blank" rel="noopener noreferrer">{item.title} ({item.date})</a></p>
          ))}
        </div>
      </div>
    </BaseLayout>
  )
}

